<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <!-- <h2 class="brand-text text-primary ml-1">Vuexy</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img :src="imgUrl" fluid alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Verificación de dos factores 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Ha recibido un mensaje de WhatsApp que contiene un código de inicio
            de sesión de dos factores.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
            >
              <!-- password -->
              <b-form-group label="Código de dos factores" label-for="code">
                <validation-provider
                  #default="{ errors }"
                  name="Codigo"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="code"
                      v-model="code"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="code"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" icon="KeyIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.code"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->

              <!-- submit button -->
              <b-button block type="submit" variant="primary">
                Verificar
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link @click.prevent="signOut">
              <feather-icon icon="ChevronLeftIcon" /> Volver para iniciar sesión
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
/* eslint-disable global-require */
import axiosIns from "@/libs/axios";
import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from "bootstrap-vue";
import { required } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      cPassword: "",
      password: "",
      code: "",
      sideImg: require("@/assets/images/pages/reset-password-v2.svg"),
      // validation
      required,
      errores: [],

      // Toggle Password
    };
  },
  watch: {
    code: function () {
      this.errores.code = [];
    },
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/reset-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.vefiry2Factor();
        }
      });
    },

    ...mapActions({
      signOutActions: "auth/signOut",
      vefiryCode: "auth/vefiryCode",
    }),
    signOut() {
      this.signOutActions()
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          console.log("error");
        });
    },
    vefiry2Factor() {
      this.loading = true;
      this.vefiryCode({
        code: this.code,
      })
        .then(() => {
          if (this.authenticated) {
            if (!this.user.estado) {
              this.$router.push({ name: "account-desactive" });
            } else if (this.user.reset_password) {
              this.$router.push({ name: "reset-password" });
            } else if (this.user.two_factor) {
              this.$router.push({ name: "verify-code" });
            } else {
              this.$router.push({ name: "escritorio" });
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errores = err.response.data.errors;
          } else {
            
            //this.signOut();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Verificacion Fallida",
                text: err.response.data.message,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
            if(err.response.data.expired){
              this.signOut()
            }
            this.loading = false;
          }
          //this.errores = err.response.data.errors;
          // console.log(err.response);
          // console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
